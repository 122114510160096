import { TokenErrorContext } from "../context/TokenErrorContext";
import { useContext } from "react";

export const useTokenErrorContext = () => {
    const context = useContext(TokenErrorContext);

    if (!context) {
        return Error('useTokenErrorContext must be used inside an TokenErrorContextProvider');
    };

    return context;
};