import { useAuthContext } from "./useAuthContext";
import { useTokenErrorContext } from "./useTokenErrorContext";


export const useXhr = () => {
    const xhr = new XMLHttpRequest();
    const { user } = useAuthContext();
    const { dispatch } = useTokenErrorContext();

    const customXhr = (formData) => {
        return new Promise((resolve, reject) => {
            const url = process.env.REACT_APP_BACKEND_URL;
            const token = user && user.token;

            xhr.open('POST', url, true);
            xhr.setRequestHeader('Authorization', `Bearer ${token && token}`);
            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        try {
                            const data = JSON.parse(xhr.responseText);

                            if (data) {
                                if (data.message === "Token Unsuccessfull!") {
                                    dispatch({ type: 'SET_TOKEN_ERROR', payload: data.message });
                                } else {
                                    resolve(data);
                                }
                            }
                        } catch (error) {
                            console.error('Error parsing JSON:', error);
                            reject(error);
                        }
                    } else {
                        console.error('HTTP error! Status:', xhr.status);
                        reject(xhr.status);
                    }
                }
            };

            xhr.send(formData);
        });
    };

    return { customXhr }
};