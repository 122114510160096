import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Modal } from "react-bootstrap";
import PageHeader from "../common/PageHeader";
import { useXhr } from "hooks/useXhr";
import { useUsersContext } from "hooks/useUsersContext";
import { Button, Spinner } from "react-bootstrap";

//Avatars
import Avatar1 from "../../assets/images/lg/avatar1.jpg";
import Avatar2 from "../../assets/images/lg/avatar2.jpg";
import Avatar3 from "../../assets/images/lg/avatar3.jpg";
import Avatar4 from "../../assets/images/lg/avatar4.jpg";
import Avatar5 from "../../assets/images/lg/avatar5.jpg";
import Avatar6 from "../../assets/images/lg/avatar6.jpg";

function Users() {
    //States
    const [user, setUser] = useState({
        name: null,
        email: null,
        password: null,
        avatar: null
    });
    const [update, setUpdate] = useState(null);
    const [changePassword, setChangePassword] = useState(null);
    const [confirm, setConfirm] = useState(null);
    const [warning, setWarning] = useState(false);
    const [status, setStatus] = useState(false);
    const [isModal, setIsModal] = useState(false);
    const [isDelete, setIsDelete] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState({
        field: null,
        note: null
    });

    //Arrays
    const avatars = [
        Avatar1,
        Avatar2,
        Avatar3,
        Avatar4,
        Avatar5,
        Avatar6
    ];
    const requiredFields = ['name', 'email', 'password', 'avatar'];
    const updateFields = ['name', 'email', 'avatar'];


    //Hooks
    const { customXhr } = useXhr()
    const { users, dispatch } = useUsersContext();

    //Functions
    const handleOnChange = (e) => {
        const { id, value } = e.target;

        if (update) {
            setUpdate(prevState => ({
                ...prevState,
                [id]: value
            }));
        } else if (changePassword) {
            setChangePassword(prevState => ({
                ...prevState,
                [id]: value
            }));
        } else {
            setUser(prevState => ({
                ...prevState,
                [id]: value
            }));
        }

        if (id === "password") {
            setWarning(false);
        };
    };

    const handleConfirm = (e) => {
        setConfirm(e.target.value);
        setWarning(false);
    };

    const handleAvatars = (e) => {
        const { alt } = e.target;
        if (update) {
            setUpdate(prevState => ({
                ...prevState,
                avatar: alt
            }));
        } else {
            setUser(prevState => ({
                ...prevState,
                avatar: alt
            }));
        }
    };

    const showAvatar = (avatar) => {
        if (avatar === "avatar1") {
            return Avatar1
        } else if (avatar === "avatar2") {
            return Avatar2
        } else if (avatar === "avatar3") {
            return Avatar3
        } else if (avatar === "avatar4") {
            return Avatar4
        } else if (avatar === "avatar5") {
            return Avatar5
        } else if (avatar === "avatar6") {
            return Avatar6
        }
    };

    const handleSubmit = async () => {

        setIsLoading(true);

        for (const field of requiredFields) {
            if (!user[field] || user[field] === "") {
                return setError({
                    field: field,
                    note: `${field === 'avatar' ? 'Lütfen bir avatar seçiniz' : "Zorunlu alan"}.`
                });
            }
        }

        if (user.password !== confirm) {
            setWarning(true);
            return;
        }

        const formData = new FormData();
        formData.append('module', 'users');
        formData.append('action', 'insert');
        formData.append('name', user.name);
        formData.append('email', user.email);
        formData.append('password', user.password);
        formData.append('avatar', user.avatar);

        const response = await customXhr(formData);

        if (response.status === "success") {
            setUser({
                name: null,
                email: null,
                password: null,
                avatar: null,
            });
            setConfirm(null);
            setWarning(false);
            setError({
                field: null,
                note: null
            });
            setIsModal(false);
            setStatus(!status);
            setIsLoading(false);
        };
    };

    const handleUpdate = async () => {

        setIsLoading(true);

        for (const field of updateFields) {
            if (!update[field] || update[field] === "") {
                return setError({
                    field: field,
                    note: `${field === 'avatar' ? 'Lütfen bir avatar seçiniz' : "Zorunlu alan"}.`
                });
            }
        };

        const formData = new FormData();
        formData.append('module', 'users');
        formData.append('action', 'update');
        formData.append('id', update.id);
        formData.append('name', update.name);
        formData.append('email', update.email);
        formData.append('avatar', update.avatar);

        const response = await customXhr(formData);

        if (response.status === "success") {
            setUpdate(null);
            setError({
                field: null,
                note: null
            });
            setStatus(!status);
            setIsLoading(false);
        };
    };

    const handleChangePassword = async () => {
        if (changePassword.password === confirm) {
            setIsLoading(true);
            const formData = new FormData();
            formData.append('module', 'users');
            formData.append('action', 'chpasswd');
            formData.append('id', changePassword.id);
            formData.append('new_password', changePassword.password);

            const response = await customXhr(formData);

            if (response.status === "success") {
                setChangePassword(null);
                setConfirm(null);
                setWarning(false);
                setStatus(!status);
                setIsLoading(false);
            };
        } else {
            setWarning(true);
        }
    };

    const handleDeleteConfirm = (row) => {
        setIsDelete(row);
    };

    const handleDelete = async (id) => {
        setIsLoading(true);
        const formData = new FormData();
        formData.append('module', 'users');
        formData.append('action', 'delete');
        formData.append('adminId', id);

        const response = await customXhr(formData);

        if (response.status === "success") {
            setStatus(!status);
            setIsDelete(null);
            setIsLoading(false);
        };
    };

    const getUsers = async () => {
        const formData = new FormData();
        formData.append('module', 'users');
        formData.append('action', 'list');

        const response = await customXhr(formData);

        if (response) {
            dispatch({ type: 'SET_USERS', payload: response });
        };
    };

    useEffect(() => {
        getUsers();
    }, [status]);

    //Columns
    var columnT = ""

    columnT = [
        {
            name: "ADMIN ID",
            selector: (row) => row.id,
            sortable: true,
            cell: (row) => <a href="#" className="fw-bold text-primary">{row.id}</a>
        },
        {
            name: "AD SOYAD",
            selector: (row) => row.name,
            sortable: true,
            cell: row => <div><img className="avatar rounded-circle" src={showAvatar(row.avatar)} alt="" /> <span className="fw-bold ms-1">{row.name}</span></div>,
            minWidth: "250px"
        },
        {
            name: "E-POSTA",
            selector: (row) => row.email,
            sortable: true
        },
        {
            name: "GÜNCELLENDİ",
            selector: (row) => row.createdAt,
            sortable: true
        },
        {
            name: "AKSİYON",
            selector: (row) => { },
            sortable: true,
            cell: (row) => <div className="btn-group" role="group" aria-label="Basic outlined example">
                <button
                    type="button"
                    className="btn btn-outline-secondary"
                    title="Bilgileri Düzenle"
                    onClick={() => { setUpdate(row) }}
                >
                    <i className="icofont-edit text-success"></i>
                </button>
                <button
                    type="button"
                    className="btn btn-outline-secondary"
                    title="Parola Değiştir"
                    onClick={() => { setChangePassword(row) }}
                >
                    <i className="icofont-key text-primary"></i>
                </button>
                <button
                    type="button"
                    className="btn btn-outline-secondary deleterow"
                    title="Sil"
                    onClick={() => handleDeleteConfirm(row)}
                >
                    <i className="icofont-ui-delete text-danger"></i>
                </button>
            </div>
        }
    ]

    return (
        <div className="container-xxl">
            <PageHeader headerTitle="Adminler" renderRight={() => {
                return <div className="col-auto d-flex w-sm-100">
                    <button className="btn btn-dark btn-set-task w-sm-100" onClick={() => { setIsModal(true) }}>
                        <i className="icofont-plus-circle me-2 fs-6"></i>
                        Yeni Admin
                    </button>
                </div>
            }} />
            <div className="row clearfix g-3">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">
                            {users &&
                                <DataTable
                                    title="Adminler"
                                    columns={columnT}
                                    data={users}
                                    defaultSortField="title"
                                    pagination
                                    selectableRows={false}
                                    className="table myDataTable table-hover align-middle mb-0 d-row nowrap dataTable no-footer dtr-inline"
                                    highlightOnHover={true}
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                centered
                show={isModal}
                onHide={() => {
                    setIsModal(false);
                    setUser({
                        name: null,
                        email: null,
                        password: null,
                        avatar: null
                    });
                    setConfirm(null);
                    setWarning(false);
                    setError({
                        field: null,
                        note: null
                    });
                    setIsLoading(false);
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fw-bold">Yeni Admin</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        <label htmlFor="name" className="form-label">İsim ve Soyisim</label>
                        <input
                            type="text"
                            className="form-control"
                            id="name"
                            onChange={handleOnChange}
                            style={{ borderColor: `${error.field === "name" ? "red" : "var(--border-color)"}` }}
                            required
                        />
                    </div>
                    {error.field === "name" &&
                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                    }
                    <div className="mb-3">
                        <label htmlFor="email" className="form-label">E-Posta</label>
                        <input
                            type="email"
                            className="form-control"
                            id="email"
                            onChange={handleOnChange}
                            style={{ borderColor: `${error.field === "email" ? "red" : "var(--border-color)"}` }}
                            required
                        />
                    </div>
                    {error.field === "email" &&
                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                    }
                    <div className="deadline-form">
                        <div className="row g-3 mb-3">
                            <div className="col-lg-6">
                                <label htmlFor="password" className="form-label">Parola</label>
                                <input
                                    type="password"
                                    className="form-control"
                                    id="password"
                                    onChange={handleOnChange}
                                    style={{
                                        borderColor: `${warning || error.field === "password" ?
                                            "red" :
                                            "var(--border-color)"
                                            }`
                                    }}
                                    required
                                />
                            </div>
                            <div className="col-lg-6">
                                <label htmlFor="confirm" className="form-label">Parola Tekrar</label>
                                <input
                                    type="password"
                                    className="form-control"
                                    id="confirm"
                                    onChange={handleConfirm}
                                    style={{ borderColor: `${warning ? "red" : "var(--border-color)"}` }}
                                    required
                                />
                            </div>
                            {warning &&
                                <label className="form-label" style={{ color: "red" }}>*Parolalar eşleşmiyor</label>
                            }
                            {error.field === "password" &&
                                <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                            }
                            <label className="form-label">Avatar Seçiniz</label>
                            {avatars.map((avatar, index) => (
                                <div
                                    key={index}
                                    className="col-2 mt-2 avatarButton"
                                    style={{
                                        opacity: `${user.avatar === `avatar${index + 1}` ? 0.5 : 1}`,
                                    }}
                                >
                                    <button onClick={handleAvatars}>
                                        <img src={avatar} alt={`avatar${index + 1}`} />
                                    </button>
                                </div>
                            ))}
                            {error.field === "avatar" &&
                                <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                            }
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        className="me-1"
                        onClick={() => handleSubmit()}
                        disabled={isLoading}
                    >
                        {
                            isLoading ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />
                                    Yükleniyor...
                                </> :
                                <>
                                    Kaydet
                                </>
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                centered
                show={update}
                onHide={() => {
                    setUpdate(null);
                    setError({
                        field: null,
                        note: null
                    });
                    setIsLoading(false);
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fw-bold">Admin Bilgilerini Güncelle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        <label htmlFor="name" className="form-label">İsim ve Soyisim</label>
                        <input
                            type="text"
                            className="form-control"
                            id="name"
                            value={update && update.name}
                            onChange={handleOnChange}
                            style={{ borderColor: `${error.field === "name" ? "red" : "var(--border-color)"}` }}
                            required
                        />
                    </div>
                    {error.field === "name" &&
                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                    }
                    <div className="mb-3">
                        <label htmlFor="email" className="form-label">E-Posta</label>
                        <input
                            type="email"
                            className="form-control"
                            id="email"
                            value={update && update.email}
                            onChange={handleOnChange}
                            style={{ borderColor: `${error.field === "email" ? "red" : "var(--border-color)"}` }}
                            required
                        />
                    </div>
                    {error.field === "email" &&
                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                    }
                    <div className="deadline-form">
                        <div className="row g-3 mb-3">
                            <label className="form-label">Avatar Seçiniz</label>
                            {avatars.map((avatar, index) => (
                                <div
                                    key={index}
                                    className="col-2 mt-2 avatarButton"
                                    style={{ opacity: `${update && update.avatar === `avatar${index + 1}` ? 0.5 : 1}` }}
                                >
                                    <button onClick={handleAvatars}>
                                        <img src={avatar} alt={`avatar${index + 1}`} />
                                    </button>
                                </div>
                            ))}
                            {error.field === "avatar" &&
                                <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                            }
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        className="me-1"
                        onClick={() => handleUpdate()}
                        disabled={isLoading}
                    >
                        {
                            isLoading ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />
                                    Yükleniyor...
                                </> :
                                <>
                                    Kaydet
                                </>
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                centered
                show={changePassword}
                onHide={() => {
                    setChangePassword(null);
                    setConfirm(null);
                    setWarning(false);
                    setIsLoading(false);
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fw-bold">Admin Parola Değiştir</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="deadline-form">
                        <div className="row g-3 mb-3">
                            <div className="col-lg-12">
                                <label htmlFor="password" className="form-label">Parola</label>
                                <input
                                    type="password"
                                    className="form-control"
                                    id="password"
                                    onChange={handleOnChange}
                                    style={{ borderColor: `${warning ? "red" : "var(--border-color)"}` }}
                                    required
                                />
                            </div>
                            <div className="col-lg-12">
                                <label htmlFor="confirm" className="form-label">Parola Tekrar</label>
                                <input
                                    type="password"
                                    className="form-control"
                                    id="confirm"
                                    onChange={handleConfirm}
                                    style={{ borderColor: `${warning ? "red" : "var(--border-color)"}` }}
                                    required
                                />
                            </div>
                            {warning &&
                                <label className="form-label" style={{ color: "red" }}>*Parolalar eşleşmiyor</label>
                            }
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        className="me-1"
                        onClick={() => handleChangePassword()}
                        disabled={isLoading}
                    >
                        {
                            isLoading ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />
                                    Yükleniyor...
                                </> :
                                <>
                                    Kaydet
                                </>
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                centered
                show={isDelete}
                onHide={() => {
                    setIsDelete(null);
                    setIsLoading(false);
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fw-bold">Uyarı</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        <div className="form-label">
                            {isDelete && isDelete.name} isimli admini silmek istediğinize emin misiniz?
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="danger"
                        className="me-1 text-white"
                        onClick={() => handleDelete(isDelete.id)}
                        disabled={isLoading}
                    >
                        {
                            isLoading ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />
                                    Siliniyor...
                                </> :
                                <>
                                    <i className="icofont-ui-delete"></i>
                                    &ensp;
                                    Sil
                                </>
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )

}

export default Users;
