import React from "react";
import PageHeader from "../../components/common/PageHeader";
import SocialMediaLinks from "../../components/Homepage/SocialMediaLinks";

function SocialMedia() {

    return (
        <div className="container-xxl">
            <div className="row clearfix g-3">
                <PageHeader headerTitle="Sosyal Medya" />
            </div>
            <div className="row justify-content-center">
                <div className="col-lg-12 col-md-12">
                    <SocialMediaLinks />
                </div>
            </div>
        </div>
    )
}


export default SocialMedia;